<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('reports.paymentsReport')"
                icon="mdi-credit-card-outline"
            >
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="print"
                    >
                        <v-icon>
                            mdi-printer-outline
                        </v-icon>
                        {{ $t("print") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-expansion-panels class="pa-5" v-model="panel" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-icon color="primary darken-1">
                            mdi-clipboard-text-search-outline
                        </v-icon>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="fromDate"
                                                            :label="
                                                                $t(
                                                                    'reports.fromDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="fromDate"
                                                        :active-picker.sync="
                                                            activePicker
                                                        "
                                                        @change="saveDate"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu2"
                                                    v-model="menu2"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="toDate"
                                                            :label="
                                                                $t(
                                                                    'reports.toDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="toDate"
                                                        :active-picker.sync="
                                                            activePicker2
                                                        "
                                                        @change="saveDate2"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-checkbox
                                            class="mt-1"
                                            v-model="showAll"
                                            :label="
                                                $t('reports.withoutTimePeriod')
                                            "
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="fiscalYear"
                                            :items="fiscalYears"
                                            item-text="title"
                                            item-value="fiscalYearGuid"
                                            :label="$t('fiscalYear.fiscalYear')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            @change="changeCurrency"
                                            persistent-hint
                                            v-model="currencyGuid"
                                            :items="currencies"
                                            item-text="currencySymbol"
                                            item-value="currencyGuid"
                                            :label="$t('voucher.currency')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-autocomplete
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="customerGuid"
                                            :items="customers"
                                            item-text="fullName"
                                            item-value="customerGuid"
                                            :label="$t('customer.fullName')"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            v-model="payStatus"
                                            :items="payStatusList"
                                            item-text="key"
                                            item-value="value"
                                            :label="$t('payStatus.payStatus')"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="getData"
                                :disabled="!valid"
                                :min-width="100"
                            >
                                <v-icon
                                    >mdi-clipboard-text-search-outline</v-icon
                                >
                                {{ $t("preview") }}
                            </v-btn>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-data-table
                :headers="headers"
                :items="payments"
                :loading="loading"
                class="pa-5"
                :loading-text="$t('loading')"
            >
                <template slot="body.append">
                    <tr>
                        <th>{{ $t("total") }}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                            {{ totalAmounts | currency("", 3) }}
                        </th>
                    </tr>
                </template>
                <template v-slot:[`item.billDate`]="{ item }">
                    {{ item.billDate | moment("yyyy-MM-DD") }}
                </template>
                <template v-slot:[`item.paymentDate`]="{ item }">
                    {{ item.paymentDate | moment("yyyy-MM-DD") }}
                </template>
                <template v-slot:[`item.receivedDate`]="{ item }">
                    <span v-if="item.isPaid">{{
                        item.receivedDate | moment("yyyy-MM-DD HH:mm:ss")
                    }}</span>
                </template>
                <template v-slot:[`item.isPaid`]="{ item }">
                    {{
                        item.isPaid == true
                            ? $t("payStatus.paid")
                            : $t("payStatus.unpaid")
                    }}
                </template>
                <template v-slot:[`item.paymentAmount`]="{ item }">
                    {{ item.paymentAmount | currency("", floatingPoints) }}
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: {
        PageHeader
    },
    data() {
        return {
            floatingPoints: 0,
            showAll: false,
            panel: [0, 1],
            fiscalYears: [],
            fiscalYear: null,
            customerGuid: "00000000-0000-0000-0000-000000000000",
            totalAmounts: null,
            date: null,
            date2: null,
            activePicker: null,
            menu: false,
            activePicker2: null,
            menu2: false,
            valid: true,
            loading: true,
            dialog: false,
            editedIndex: -1,
            currencyGuid: null,
            selecteditemTypes: null,
            fromDate: moment(new Date()).format("yyyy-MM-DD"),
            toDate: moment(new Date()).format("yyyy-MM-DD"),
            headers: [
                {
                    text: this.$t("customer.fullName"),
                    value: "fullName"
                },
                {
                    text: this.$t("bill.billNumber"),
                    value: "billNumber"
                },
                {
                    text: this.$t("bill.billDate"),
                    value: "billDate"
                },
                {
                    text: this.$t("payments.paymentNumber"),
                    value: "paymentNumber"
                },
                {
                    text: this.$t("payments.paymentAmount"),
                    value: "paymentAmount"
                },
                {
                    text: this.$t("payments.paymentDate"),
                    value: "paymentDate"
                },
                {
                    text: this.$t("payments.receivedDate"),
                    value: "receivedDate"
                },
                {
                    text: this.$t("payments.isPaid"),
                    value: "isPaid"
                }
            ],
            payStatusList: [
                {
                    key: this.$t("payStatus.paid"),
                    value: true
                },
                {
                    key: this.$t("payStatus.unpaid"),
                    value: false
                },
                {
                    key: this.$t("payStatus.all"),
                    value: null
                }
            ],
            payStatus: null,
            currencies: [],
            customers: [],
            payments: [],
            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        this.loading = true;
        this.getMainData();
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    methods: {
        close() {
            this.dialog = false;
            //this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        getData() {
            if (this.$refs.form.validate()) {
                axios
                    .get("Payments/PaymentsReport", {
                        params: {
                            currencyGuid:
                                this.currencyGuid == null
                                    ? this.currencies.filter(function(obj) {
                                          return obj.isMain == true;
                                      })[0].currencyGuid
                                    : this.currencyGuid,
                            fiscalYearGuid: this.fiscalYear,
                            fromDate: this.fromDate,
                            toDate: this.toDate,
                            customerGuid: this.customerGuid,
                            payStatus: this.payStatus,
                            showAll: this.showAll
                        }
                    })
                    .then(response => {
                        this.payments = response.data.data;

                        this.totalAmounts =
                            Math.round(
                                (this.payments.reduce(
                                    (a, b) => a + (b["paymentAmount"] || 0),
                                    0
                                ) +
                                    Number.EPSILON) *
                                    100
                            ) / 100;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.dialog = false;
                    });
            }
        },
        getMainData() {
            axios
                .get("Customers/Get")
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.customers = response.data.data;
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                    this.dialog = true;
                });

            axios
                .get("Currencies/Get")
                .then(response => {
                    this.currencies = response.data.data;
                    this.currencyGuid = this.currencies.filter(function(obj) {
                        return obj.isMain == true;
                    })[0].currencyGuid;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("FiscalYears/Get")
                .then(response => {
                    this.fiscalYears = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        saveDate2(date) {
            this.$refs.menu2.save(date);
        },
        print() {
            var defaultFiscalYear =
                this.fiscalYear == null
                    ? this.fiscalYears.filter(function(obj) {
                          return obj.isActive == true;
                      })[0].fiscalYearGuid
                    : this.fiscalYear;
            axios({
                url: "Payments/PrintReport",
                method: "GET",
                responseType: "blob",
                params: {
                    fiscalYearGuid: defaultFiscalYear,
                    currencyGuid:
                        this.currencyGuid == null
                            ? this.currencies.filter(function(obj) {
                                  return obj.isMain == true;
                              })[0].currencyGuid
                            : this.currencyGuid,
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    customerGuid: this.customerGuid,
                    payStatus: this.payStatus,
                    showAll: this.showAll
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "تقرير الاقساط.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        changeCurrency(val) {
            this.floatingPoints = this.currencies.find(
                x => x.currencyGuid == val
            ).floatingPoints;
        }
    }
};
</script>
